/* eslint-disable import/no-extraneous-dependencies */
import { Components as Iam } from '@/types/client.iam'
import { Context } from '@nuxt/types'
import { computed, ref } from '@nuxtjs/composition-api'
import { useSinglePromise } from '@/composables/single-promise'

type IgnoreParts = 'name' | 'middleName' | 'familyName'
interface ProfileNameParts {
    familyName?: string | null
    name?: string | null
    middleName?: string | null
}
export const getFullName = (
    basicProfile: ProfileNameParts | undefined | null,
    noName: string = 'Пользователь Workle',
    ignore: Array<IgnoreParts> | null = null
) => {
    if (!basicProfile) return noName

    const copy = structuredClone(basicProfile)
    if (ignore) {
        ignore?.forEach(item => delete copy[item])
    }

    const { name, familyName, middleName } = copy
    const fullName = !middleName || ignore?.includes('middleName')
        ? `${name || ''} ${familyName || ''}`
        : `${familyName || ''} ${name || ''} ${middleName || ''}`

    return fullName.trim() || noName
}

export const user = ref<Iam.Schemas.UserProfileDetails>({})
export const userFullName = computed(() => getFullName(user.value.profile, 'Пользователь Workle'))
export const fetchUser = useSinglePromise(async (context: unknown) => {
    const { $apiIam } = context as Context

    try {
        const { data } = await $apiIam.workleUserProfilesGet()

        user.value = data
    } catch (error) {
        console.error(error)
    }
})
