// eslint-disable-next-line import/no-extraneous-dependencies
import { Middleware } from '@nuxt/types'
// eslint-disable-next-line import/extensions
import { ref } from '@nuxtjs/composition-api'
import { navigationFromPlugin } from '@/plugins/link-handler'

export const filtersInQuery = ref<string[]>([])
export const isSaveSearchParam = ref(false)

const mergeQueryParamsMiddleware: Middleware = ({ route, from, redirect }) => {
  if (navigationFromPlugin.value) {
    navigationFromPlugin.value = false

    return undefined
  }

  const fromQuery = from.query || {}
  const toQuery = route.query || {}

  const mergedQuery = { ...fromQuery, ...toQuery }

  if (route.name?.includes('showcases-categories') && from.name?.includes('showcases-categories')) {
    if (isSaveSearchParam.value === false) {
      filtersInQuery.value.forEach((filter: string) => {
          if (
            (toQuery[filter] === undefined && fromQuery[filter])
              || toQuery[filter] === fromQuery[filter]
            ) {
            delete mergedQuery[filter]
          }
        })
    }

    isSaveSearchParam.value = false

    if (JSON.stringify(toQuery) !== JSON.stringify(mergedQuery)) {
      redirect({ path: route.path, query: mergedQuery })
    }

    return undefined
  }

  if (JSON.stringify(toQuery) !== JSON.stringify(mergedQuery)) {
    redirect({ path: route.path, query: mergedQuery })
  }
}

export default mergeQueryParamsMiddleware
