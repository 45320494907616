import toCamelCase from 'lodash/camelCase'
import { OpenAPIClientAxios, Document as OpenAPI3Document } from '@fedorif/openapi-client-axios-custom'
import clientCustomerDefinition from '@/swagger/customer.json'
import { Client as ClientCustomer } from '@/types/client.customer'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Plugin } from '@nuxt/types'

declare module '@nuxt/types' {
    interface NuxtAppOptions {
        $apiCustomer: ClientCustomer
    }

    interface Context {
        $apiCustomer: ClientCustomer
    }
}

const AxiosClientCustomer: Plugin = async ({ $axios }, inject) => {
    const api = new OpenAPIClientAxios({
        transformOperationName: o => toCamelCase(o),
        definition: clientCustomerDefinition as unknown as OpenAPI3Document,
        axiosInstance: $axios
    })

    const client = await api.getClient<ClientCustomer>()

    inject('apiCustomer', client)
}

export default AxiosClientCustomer
