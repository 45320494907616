/* eslint-disable node/no-callback-literal */
type Arguments<T extends Function, U = unknown> = T extends (...args: infer A) => U ? A : never

export const useSinglePromise = <T extends Function, U extends Arguments<T>>(callback: T) => {
    let fetchPromise: Promise<T> | null = null
    const callbackWrapper = async (...args: U) => {
        const result = await callback(...args)

        fetchPromise = null

        return result
    }

    return (...args: U) => {
        if (!fetchPromise) {
            fetchPromise = callbackWrapper(...args)
        }

        return fetchPromise
    }
}
